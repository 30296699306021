import React from 'react';

import iconContainer from './Container';
import icons from './icons';

export const IconCountryFlag = React.memo(({ countryCode }) => (
  <img
    loading="lazy"
    width="20"
    src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
    srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
    alt=""
  />
));

export const IconCheck = iconContainer(icons.check);
export const IconClock = iconContainer(icons.clock);
export const IconAlertCircle = iconContainer(icons.alertCircle);
export const IconAlertTriangle = iconContainer(icons.alertTriangle);

export const IconHome = iconContainer(icons.home);
export const IconClipboard = iconContainer(icons.clipboard);
export const IconDollar = iconContainer(icons.dollar);
export const IconCalendar = iconContainer(icons.calendar);

export const IconFile = iconContainer(icons.file);
export const IconFilePlus = iconContainer(icons.file_plus);

export const IconArrowRight = iconContainer(icons.arrowRight);
export const IconArrowLeft = iconContainer(icons.arrowLeft);

export const IconSmileMeh = iconContainer(icons.smileMeh);
export const IconSmile = iconContainer(icons.smile);

export const IconTool = iconContainer(icons.tool);
export const IconPhone = iconContainer(icons.phone);
export const IconUser = iconContainer(icons.user);
export const IconUsers = iconContainer(icons.users);
export const IconCreditCard = iconContainer(icons.creditCard);
export const IconSun = iconContainer(icons.sun);
export const IconFeather = iconContainer(icons.feather);
export const IconLogin = iconContainer(icons.login);
export const IconLogout = iconContainer(icons.logout);
export const IconDownload = iconContainer(icons.download);
export const IconUpload = iconContainer(icons.upload);

export const IconEyeOpen = iconContainer(icons.eyeOpen);
export const IconEyeClose = iconContainer(icons.eyeClose);
export const IconBookOpen = iconContainer(icons.bookOpen);
export const IconActivity = iconContainer(icons.activity);
export const IconStar = iconContainer(icons.star);
export const IconGift = iconContainer(icons.gift);
export const IconEdit3 = iconContainer(icons.edit3);
export const IconPlus = iconContainer(icons.plus);
export const IconChevronUp = iconContainer(icons.chevronUp);
export const IconChevronDown = iconContainer(icons.chevronDown);
export const IconMoreVertical = iconContainer(icons.moreVertical);
export const IconList = iconContainer(icons.list);
export const IconSearch = iconContainer(icons.search);
export const IconTrello = iconContainer(icons.trello);
export const IconRotate = iconContainer(icons.rotate);
export const IconServer = iconContainer(icons.server);
export const IconX = iconContainer(icons.x);
export const IconChevronsRight = iconContainer(icons.chevronsRight);
export const IconLoader = iconContainer(icons.loader);
export const IconInfoCircle = iconContainer(icons.infoCircle);
export const IconEmail = iconContainer(icons.email);
export const IconSave = iconContainer(icons.saveDisk);
export const IconDatabase = iconContainer(icons.database);

export const IconFolder = iconContainer(icons.folder);

export const IconSettings = iconContainer(icons.settings);
export const IconHeart = iconContainer(icons.heart);
export const IconMessageCircle = iconContainer(icons.messageCircle);
export const IconMonitor = iconContainer(icons.monitor);
export const IconCarbon = iconContainer(icons.carbon);
export const IconHeadphone = iconContainer(icons.headphone);
export const IconSoftware = iconContainer(icons.software);
export const IconExandMore = iconContainer(icons.expendMore);
export const IconExandLess = iconContainer(icons.expandLess);
export const IconArrowForward = iconContainer(icons.arrowForward);
export const IconArrowDownLine = iconContainer(icons.arrowDownLine);
export const IconArrowUpLine = iconContainer(icons.arrowUpLine);
export const IconArrowSingleLeft = iconContainer(icons.arrowSingleLeft);
export const IconArrowSingleRight = iconContainer(icons.arrowSingleRight);
export const IconExport = iconContainer(icons.export);
export const IconSmileNone = iconContainer(icons.smileNone);
export const IconResendEmail = iconContainer(icons.resendEmail);
export const IconLike = iconContainer(icons.like);
export const IconArrowDown = iconContainer(icons.arrowDown);
export const IconArrowUp = iconContainer(icons.arrowUp);
export const IconCopyLink = iconContainer(icons.copyLink);
export const IconTrash = iconContainer(icons.trash);
export const IconVacation = iconContainer(icons.vacation);
export const IconBriefcase = iconContainer(icons.briefcase);
export const IconExternalLink = iconContainer(icons.externalLink);

// NOTE: myFinance
export const IconTimesheets = iconContainer(icons.timesheets);
export const IconRequests = iconContainer(icons.requests);
export const IconSickLeaves = iconContainer(icons.sickLeaves);
export const IconPayouts = iconContainer(icons.payouts);

export const IconInvoice = iconContainer(icons.invoice);

export const IconDollarInvoice = iconContainer(icons.dollarInvoice);
export const IconDoubleArrowDown = iconContainer(icons.doubleArrowDown);
export const IconDoubleArrowUp = iconContainer(icons.doubleArrowUp);
export const IconRefresh = iconContainer(icons.refresh);
export const IconCornerRightDown = iconContainer(icons.cornerRightDown);
export const IconTelevision = iconContainer(icons.television);
export const IconShield = iconContainer(icons.shield);
export const IconRevert = iconContainer(icons.revert);
export const IconBambooHR = iconContainer(icons.bambooHR);
export const IconQuestion = iconContainer(icons.question);
export const IconCancel = iconContainer(icons.cancel);

export const IconNetwork = iconContainer(icons.network);
export const IconLinkedin = iconContainer(icons.linkedin);
